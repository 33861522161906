import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import store from "./../store";
import axios from "axios";
const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // }

  {
    path: '/',
    name: 'LoginPage',
    meta: {
      requiresAuth: false,
    },
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: '/dashboard',
    props: true,
    component: () => import('../layouts/BaseLayout.vue'),
    meta: {
      requiresAuth: true,
      isSuperAdmin: true,
    },
    children: [
      {
        path: '/home',
        name: 'CategoryPage',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/CategoryPage.vue'),
      },
      {
        path: '/AboutMe',
        name: 'AboutMe',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/AboutMe.vue'),
      },
      {
        path: '/blog',
        name: 'BlogPage',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/BlogPage.vue'),
      },
      {
        path: '/HomeView',
        name: 'HomeView',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: '/banner',
        name: 'AddBanner',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/AddBanner.vue'),
      },
      {
        path: '/BlogDetail',
        name: 'BlogDetail',
        meta: {
          requiresAuth: true,
      isSuperAdmin: true,

        },
        component: () => import('../views/BlogDetail.vue'),
      },
      
      
      // Add your other child routes here
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  //new lines
  localStorage.setItem("PRE", from.path);
  if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
    axios({
            method: 'get',
            url: '/token/authenticate',
            headers: {
                'token': localStorage.getItem('token')
            }
        }).then(response => {
            if (!response.data.status) {
              console.log("router logiut")
                          localStorage.clear();
                          router.push("/");
                          // this.$store.commit("logoutUser", true);
                return
            }
        })
        .catch(err => {
            var msg = err;
            console.log(msg)
        });
}
  // end  of newely added lines
  // if (
  //   to.matched.some((route) => route.meta.requiresAuth == true) &&
  //   store.state.isLoggedIn == false
  // ) {
  //   // console.log(from);
  //   next({ name: "LoginPage", params: { lastPage: from } });
  //   return;
  // } 

  if (
    to.name == "LoginPage" &&
    store.state.isLoggedIn == true 
  ) {
    next({ name: "CategoryPage" });
    return;
  }

// Redirection userwise
if (
  to.matched.some((route) => route.meta.isSuperAdmin == false) && from.name != "LoginPage" &&
  store.state.isLoggedIn == true
) {
  next({ name: "CategoryPage" });
  return;
}


  next();
});
export default router
