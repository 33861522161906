
import { createStore } from 'vuex';
import router from '@/router'; // Import the Vue Router instance

export default createStore({
  state() {
    return {
      isAuthenticated: false,
      isLoggedIn:false,
    };
  },
  mutations: {
    login(state) {
      state.isAuthenticated = true;
      state.isLoggedIn = true;
      router.push('/home'); // Now you can use router.push
    },
    logout(state) {
      state.isAuthenticated = false;
      state.isLoggedIn = false;

      localStorage.clear();
      router.push('/'); // Use router.push with the appropriate route
    },
  },
  actions: {
    // You can define actions here if needed
  },
  getters: {
    // You can define getters here if needed
  },
});

