import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import axios from 'axios';
import './assets/style.css';
import './assets/font.css';
import VueElementLoading from 'vue-element-loading';
import Vue3Editor from 'vue3-editor';
import ImageUploadVue from 'image-upload-vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
loadFonts()


const app = createApp(App);
app.component('QuillEditor', QuillEditor)
app.component('VueElementLoading', VueElementLoading);
app.component('Vue3Editor', Vue3Editor);
app.config.globalProperties.$ImageUploadVue = ImageUploadVue;

// axios.defaults.baseURL = 'http://192.168.52.54:5555';
// app.config.globalProperties.baseURL = "http://192.168.52.54:5555";
// app.config.globalProperties.mediaURL = "http://192.168.52.54:5555/file/get/";

// app.config.globalProperties.mediaURL = "http://192.168.216.78:5555/local/file/get/";



axios.defaults.baseURL = 'https://api.spiritofanson.com/';
app.config.globalProperties.baseURL = "https://api.spiritofanson.com/"
app.config.globalProperties.mediaURL = "https://api.spiritofanson.com/file/get/"

app.config.globalProperties.$axios = axios;

app
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');


